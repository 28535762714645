@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scroll bar hidden */
::-webkit-scrollbar {
  display: none;
}

/* Scroll bar hidden */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Scroll bar hidden */
body::-webkit-scrollbar {
  display: none;
}
